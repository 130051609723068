import React, { useState } from 'react';
import { SystemProps, Flex, Stack } from '@storyofams/react-ui';
import { Button, Heading, Text, Input } from '~components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { AnimatePresence, motion } from 'framer-motion';
import qs from 'querystring';
import * as Yup from 'yup';
import jsonp from 'jsonp';

type SubscribeBlockProps = {
  content: {
    title?: string,
    body_text?: string,
    button_text?: string,
    email_input_placeholder?: string,
    subscription_text?: string,
    use_custom_button_color: boolean,
    background_color?: {
      color?: string
    },
    font_color?: {
      color?: string
    }
  };
} & SystemProps;

const schema = (formType) =>
  Yup.object().shape({
    [`${formType}-email`]: Yup.string()
      .email()
      .required('Please fill in your email')
      .trim(),
  });

export const SubscribeBlock = ({ content, ...props }: SubscribeBlockProps) => {
  let localCustomFontProps = {} as any;
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(schema('footer')),
  });
  const onSubmit = async (values) => {
    jsonp(
      `//plantb.us2.list-manage.com/subscribe/post-json?u=e473f0b0d8326d00d06f88655&id=3393f71c9e&${qs.stringify(
        { EMAIL: values[`footer-email`] },
      )}`,
      { param: 'c' },
      (err, data) => {
        if (!err) {
          setSubmitted(data.msg);
          reset();
        }
      },
    );
  };

  if (!!content?.font_color?.color) {
    localCustomFontProps.color = content?.font_color?.color
  }

  return (
    <Flex
      flexDirection={'column'}
      maxWidth={['100%', '310px']}
      width={['100%']}
      p={2}
      {...props}
      backgroundColor={content?.background_color?.color}
    >
      {!!content?.title
        ? (
          <Heading
            as="h3"
            width="100%"
            variant="sh3"
            fontSize={2.5}
            lineHeight={'150%'}
            fontWeight="800"
            {...localCustomFontProps}
          >{content?.title}</Heading>
        ) : null
      }
      {!!content?.body_text
        ? <Text
            mt={[2, 5]}
            fontSize={2}
            lineHeight={'150%'}
            fontWeight="600"
            {...localCustomFontProps}
          >{ content?.body_text }</Text>
        : null
      }
      <Flex
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        width="100%"
        alignItems="center"
        justifyContent="center"
        mt={3}
      >
        <AnimatePresence>
          {submitted ? (
            <motion.div
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ ease: 'easeInOut', duration: 0.24 }}
            >
              {!!content?.subscription_text
                ? <Text
                    fontWeight={'800'}
                    {...localCustomFontProps}
                  >{content?.subscription_text}</Text>
                : ''
              }
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 1 }}
              exit={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              transition={{ ease: 'easeInOut', duration: 0.24 }}
              style={{ width: '100%' }}
            >
              <Stack
                space={2}
                // @ts-ignore
                flexDirection={['column']}
                width={['100%', 'auto']}
                {...props}
              >
                <Input
                  type="email"
                  placeholder={!!content?.email_input_placeholder ? content?.email_input_placeholder : ''}
                  width={['100%']}
                  {...register(`footer-email`)}
                  error={errors?.[`footer-email`]?.message}
                />
      
                <Button
                  variant='button_type_1'
                  type="submit"
                  isLoading={isSubmitting}
                  color={'#0D1922'}
                  px={1.5}
                  width={'fit-content'}
                  alignSelf={'flex-end'}
                >
                  {!submitted ? 
                    !!content?.button_text ? content?.button_text : 'Subscribe'
                    : ''
                  }
                </Button>
              </Stack>
            </motion.div>
          )}
        </AnimatePresence>
      </Flex>
    </Flex>
  );
};
